import { Link } from 'react-router-dom';
import games from "./Games";
import DownLoadLinks from './DownloadLinks';

export default function GameHighlights()
{        
    return(
        <div id="GameHighlights" className="container d-flex flex-column align-items-center text-center">
            <div className="row">
                <h1>Our games</h1>
                <p>Take a look at some of our puzzle game highlights.</p>
                <div className="call-out-container">
                    <Link to="/games" className="call-out">
                        See all games
                        <i className="fa-solid fa-circle-arrow-right fa-xl call-out-icon"></i>
                    </Link>
                </div>
            </div>
            <div id="game-highlight-cards" className="row">
                <div className="col">
                    {games.map((game, i)=>{
                        return (
                            <div className="card homepage-card" key={i}>                                
                                <img src={game.img} className="mx-auto d-block" alt="..." loading="lazy"/>                        
                                <div className="card-body">
                                    <h5 className="card-title">{game.title}</h5>
                                    <DownLoadLinks googlePlayLink={game.googlePlayLink} appStoreLink={game.appStoreLink} />
                                </div>
                            </div>
                        );
                    })}                    
                </div>
            </div>
        </div>
    );
}