import GameCards from "../components/GameCards";

export default function Games(){
    return(
        <>
            <div id="gamePageHeader" className="container header d-flex flex-column align-items-center text-center">
                <div className="row">
                    <h1>Puzzle games for entertainment and training your brain</h1>
                    <p>We create enjoyable puzzle games to help you stay mentally active and offer you entertainment during the small breaks fo your day.</p>                
                </div>
            </div>            
            <div className="container d-flex flex-column align-items-center text-center">
                <GameCards />
            </div>
        </>
    )    
}