import wordSearchQuestImg from '../assets/icon.png';

const games = [
    {
        img: wordSearchQuestImg,
        title: "Word Search Quest",  
        description: "Search unique hidden words in 1k+ different levels across progressively more difficult word categories and unlock beautiful landscape backgrounds.",
        googlePlayLink: "xyz",
        appStoreLink: "",
    },
]

export default games;