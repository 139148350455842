import Header from '../components/Header';
import GameHighlights from '../components/GameHighlights';

export default function Home(){
    return(
        <>
            <Header />
            <GameHighlights />
        </>        
    );
}