import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg bg-white">
            <div className="container">
                <Link to="/" className="navbar-brand">
                    starfish<span className="focus">lab</span>
                </Link>            
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <Link to="/about" className="nav-link">About</Link>
                        <Link to="/games" className="nav-link">Games</Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}