export default function About(){
    return(
        <>
            <div className="container header d-flex flex-column align-items-center text-center">
                <div className="row">
                    <h1>We love making enjoyable puzzle games</h1>
                    <p>Starfish Lab is a young mobile gaming studio, founded in 2024. We make exciting free-to-play puzzle games and our mission is to bring joy and entertainment to our users and help them stay mentally active. We launched our first game "Word Search Quest" on Android initially in May 2024.</p>
                </div>
            </div>
            <div className="container max-width d-flex flex-column align-items-center text-center">
                <div className="row">
                    <h1>Our team</h1>
                    <p>The Starfish Lab team is a small, ambitious and nimble group of Silicon Valley and gaming industry veterans. We are globally distributed and are looking to hire soon. Stay on the lookout for interesting remote work opportunities and check back here in due course.</p>
                </div>
            </div>
        </>
    )    
}