import { Link } from 'react-router-dom';

export default function Header()
{
    return(
        <div className="header container d-flex flex-column align-items-center text-center">
            <div className="row">
                <h1>Enjoyable, free-to-play puzzle games for everyone</h1>
                <p>We are Starfish Lab, a young mobile gaming studio passionate about making enjoyable and entertaining puzzle games for everyone. Our games are free-to-play and offer long-lasting entertainment and education.</p>
                <div className="call-out-container">
                    <Link to="/about" className="call-out">
                        Learn more about us
                        <i className="fa-solid fa-circle-arrow-right fa-xl call-out-icon">
                    </i></Link>
                </div>
            </div>
        </div>
    )
}