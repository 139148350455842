import { Link } from 'react-router-dom';

export default function Footer()
{
    return(
        <footer id="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col text-center">
                        <span className="small">&#169; Starfish Lab Games LLC</span>
                    </div>   
                    <div className="col-6">                 
                        <div className="row text-center">
                            <div className="col">
                                <Link to="/privacy" className="footer-link small">Privacy Policy</Link>
                            </div>
                            <div className="col">
                                <Link to="/terms" className="footer-link small">Terms of Service</Link>
                            </div>
                            <div className="col">
                                <a href="mailto:contact@starfishlab.xyz" className="footer-link small">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div className="col text-center">                        
                        <a href="#" className="footer-social"><i className="fa-brands fa-linkedin fa-lg"></i></a>
                        <a href="#" className="footer-social"><i className="fa-brands fa-youtube fa-lg"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}