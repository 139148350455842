export default function DownLoadLinks(props) {

    const googlePlayColClass = props.appStoreLink ? 'col-md-6' : 'col-md-12';
    const appeStoreColClass = props.googlePlayLink ? 'col-md-6' : 'cold-md-12';

    return (
        <>
            <div className="row align-center">
                {props.googlePlayLink && (
                <div className={googlePlayColClass}>
                    <a href={props.googlePlayLink}><img src="/downloadGooglePlay.svg" className="donwloadImg" alt="GooglePlay"/></a>
                </div>
                )}
                {props.appStoreLink && (
                <div className={appeStoreColClass}>
                    <a href={props.appStoreLink}><img src="/downloadAppStore.svg" className="donwloadImg" alt="AppStore"/></a>
                </div>
                )}                
            </div>
        </>
    );
}