import games from "./Games";
import DownLoadLinks from './DownloadLinks';

export default function GameCards(){
    return (
        <>
            <div id="game-cards" className="row">
                {games.map((game)=>{
                    return (
                        <div className="col-sm-6">
                            <div className="card">
                                <img src={game.img} className="card-img-top" alt="..." loading="lazy"/>
                                <div className="card-body game-card-body">                                    
                                    <h5 className="card-title">{game.title}</h5>
                                    <p className="card-text">{game.description}</p>
                                    <DownLoadLinks googlePlayLink={game.googlePlayLink} appStoreLink={game.appStoreLink} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}